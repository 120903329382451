var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    [
      _c(
        "span",
        {
          directives: [
            {
              name: "b-toggle",
              rawName: "v-b-toggle.administration-add-organisation",
              modifiers: { "administration-add-organisation": true },
            },
          ],
        },
        [_vm._t("default")],
        2
      ),
      _c(
        "b-sidebar",
        {
          attrs: {
            "sidebar-class": "big_sidebar",
            id: "administration-add-organisation",
            right: "",
            backdrop: "",
            "no-slide": "",
            lazy: "",
            shadow: "",
          },
          on: { shown: _vm.showSidebar },
          model: {
            value: _vm.show,
            callback: function ($$v) {
              _vm.show = $$v
            },
            expression: "show",
          },
        },
        [
          _c(
            "div",
            { staticClass: "px-3 py-2" },
            [
              _c(
                "b-overlay",
                { attrs: { show: _vm.loading, rounded: "sm" } },
                [
                  _c(
                    "b-alert",
                    { attrs: { show: "" } },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("NOTE.ONLY_AS_SERVICE")) +
                          "\n          "
                      ),
                      _c("br"),
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("NOTE.ORGANISATION_OWN_NODE")) +
                          "\n          "
                      ),
                      _c(
                        "router-link",
                        { attrs: { to: { name: "Administration.Nodes" } } },
                        [_vm._v(_vm._s(_vm.$t("CLICK_HERE")))]
                      ),
                    ],
                    1
                  ),
                  _vm.step == 1
                    ? _c(
                        "div",
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                "label-cols-md": "4",
                                label: _vm.$t("NAME"),
                              },
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  trim: "",
                                  required: "",
                                  type: "text",
                                  name: "addOrganisationName",
                                },
                                model: {
                                  value: _vm.addOrganisationName,
                                  callback: function ($$v) {
                                    _vm.addOrganisationName = $$v
                                  },
                                  expression: "addOrganisationName",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                "label-cols-md": "4",
                                label: _vm.$t("ORGANISATION.UNIQUE_IDENTIFIER"),
                              },
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  trim: "",
                                  required: "",
                                  id: "input-unique-identifier",
                                  type: "text",
                                  name: "addOrganisationUniqueIdentifier",
                                  state: _vm.organisationRegistered,
                                },
                                model: {
                                  value: _vm.addOrganisationUniqueIdentifier,
                                  callback: function ($$v) {
                                    _vm.addOrganisationUniqueIdentifier = $$v
                                  },
                                  expression: "addOrganisationUniqueIdentifier",
                                },
                              }),
                              _c(
                                "b-form-invalid-feedback",
                                {
                                  attrs: {
                                    id: "input-unique-identifier-feedback",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm.$t("ERROR.ALREADY_REGISTERED")
                                      ) +
                                      "\n            "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-button",
                            {
                              staticClass: "btn-fill",
                              attrs: {
                                disabled: _vm.disabledStep2,
                                variant: "primary",
                              },
                              on: { click: _vm.gotoStep2 },
                            },
                            [_vm._v(_vm._s(_vm.$t("NEXT")))]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.step == 2
                    ? _c(
                        "div",
                        [
                          _c("div", { staticClass: "info-header" }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$t("ORGANISATION.ADMINISTRATOR")) +
                                "\n          "
                            ),
                          ]),
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                "label-cols-md": "4",
                                label: _vm.$t("EMAIL"),
                              },
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  trim: "",
                                  id: "input-email",
                                  required: "",
                                  type: "text",
                                  state: _vm.emailRegistered,
                                  name: "email",
                                },
                                model: {
                                  value: _vm.addEmail,
                                  callback: function ($$v) {
                                    _vm.addEmail = $$v
                                  },
                                  expression: "addEmail",
                                },
                              }),
                              _c(
                                "b-form-invalid-feedback",
                                { attrs: { id: "input-email-feedback" } },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm.$t("ERROR.ALREADY_REGISTERED")
                                      ) +
                                      "\n            "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                "label-cols-md": "4",
                                label: _vm.$t("NAME"),
                              },
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  trim: "",
                                  required: "",
                                  type: "text",
                                  name: "name",
                                },
                                model: {
                                  value: _vm.addName,
                                  callback: function ($$v) {
                                    _vm.addName = $$v
                                  },
                                  expression: "addName",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                "label-cols-md": "4",
                                label: _vm.$t("LANGUAGE.TITLE"),
                              },
                            },
                            [
                              _c(
                                "b-form-select",
                                {
                                  attrs: {
                                    name: "add_organisation_select_language",
                                  },
                                  model: {
                                    value: _vm.addLang,
                                    callback: function ($$v) {
                                      _vm.addLang = $$v
                                    },
                                    expression: "addLang",
                                  },
                                },
                                _vm._l(
                                  _vm.sefos_locales,
                                  function (value, key) {
                                    return _c(
                                      "b-form-select-option",
                                      { key: key, attrs: { value: key } },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(_vm.$t("LANGUAGE." + key)) +
                                            "\n              "
                                        ),
                                      ]
                                    )
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                "label-cols-md": "4",
                                label: _vm.$t("AUTH_METHODS.SELECT_METHOD"),
                              },
                            },
                            [
                              _c("b-form-select", {
                                attrs: { options: _vm.options },
                                model: {
                                  value: _vm.selectedIndex,
                                  callback: function ($$v) {
                                    _vm.selectedIndex = $$v
                                  },
                                  expression: "selectedIndex",
                                },
                              }),
                              _vm.childs.length != 0
                                ? _c(
                                    "div",
                                    [
                                      _c("b-form-select", {
                                        staticClass: "mt-2 mb-2",
                                        attrs: { options: _vm.childs_options },
                                        model: {
                                          value: _vm.selectedChildIndex,
                                          callback: function ($$v) {
                                            _vm.selectedChildIndex = $$v
                                          },
                                          expression: "selectedChildIndex",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.auth_type == "se-eid"
                                ? _c("Pnr", {
                                    attrs: { required: "" },
                                    on: { changed: _vm.changedData },
                                  })
                                : _vm._e(),
                              _vm.showIdentifier
                                ? _c("Identifier", {
                                    staticClass: "mt-2",
                                    attrs: {
                                      placeholder: _vm.$t("UNIQUE_IDENTIFIER"),
                                    },
                                    on: { changed: _vm.changedData },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c("hr", { staticClass: "p-0 m-0 mt-2 mb-2" }),
                          _c("b-button", { on: { click: _vm.gotoStep1 } }, [
                            _vm._v(_vm._s(_vm.$t("BACK"))),
                          ]),
                          _c(
                            "b-button",
                            {
                              staticClass: "btn-fill",
                              attrs: { variant: "primary" },
                              on: { click: _vm.skipStep2 },
                            },
                            [_vm._v(_vm._s(_vm.$t("SKIP_THIS_STEP")))]
                          ),
                          _c(
                            "b-button",
                            {
                              staticClass: "btn-fill",
                              attrs: {
                                disabled: !_vm.validEmail,
                                variant: "primary",
                              },
                              on: { click: _vm.gotoStep3 },
                            },
                            [_vm._v(_vm._s(_vm.$t("NEXT")))]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.step == 3
                    ? _c(
                        "div",
                        [
                          _c("div", { staticClass: "info-header" }, [
                            _vm._v(_vm._s(_vm.$t("PRICES"))),
                          ]),
                          _c("Prices", { attrs: { prices: _vm.prices } }),
                          _c("hr", { staticClass: "p-0 m-0 mt-2 mb-2" }),
                          _c("b-button", { on: { click: _vm.gotoStep2 } }, [
                            _vm._v(_vm._s(_vm.$t("BACK"))),
                          ]),
                          _c(
                            "b-button",
                            {
                              staticClass: "btn-fill",
                              attrs: {
                                disabled: !_vm.validEmail,
                                variant: "primary",
                              },
                              on: { click: _vm.createOrganisation },
                            },
                            [_vm._v(_vm._s(_vm.$t("SUBMIT")))]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }